import React, {useState} from 'react';
import styled from '@emotion/styled';
import * as FlexWebChat from '@twilio/flex-webchat-ui';
import type {MessageBubbleProps} from '@twilio/flex-ui-core/src/components/channel/MessageBubble';
import type {ChatActionSendMessageTextPayload} from '@twilio/flex-ui-core/src/actions/RegisterActions';
import {captureMessage, Severity, withScope} from '@sentry/browser';
import getParsedShortcodes from '../autopilot/getParsedShortcodes';
import {colors} from '../brandedColors';

// Workaround to get rounded corners between MessageBubble body and shortcodes.
const StyledInvertedTopRoundedCorners = styled('div')`
  background-color: #fff;
  margin: -4px 0 0 0;
  width: 100%;

  &:before {
    content: '';
    display: block;
    height: 4px;
    width: 100%;
    background-color: ${colors.lightGray};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const StyledWrapper = styled('div')`
  padding: 5px 0;
  background-color: #fff;
`;

const StyledShortcodeButtonGroup = styled('div')`
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px 0px;
  border: 1px solid rgb(206, 212, 217);
  border-radius: 4px;

  > button:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  > button:last-of-type {
    border-bottom: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const StyledShortcodeButton = styled('button')`
  cursor: pointer;
  appearance: none;
  display: inline-block;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ced4d9;
  border-width: 0 0 1px 0;
  padding: 6px;
  color: ${colors.blue};
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:focus {
    color: #316da1;
    background-color: #fafafb;
  }
`;

const AutopilotMessageBubbleShortcodesContent = ({message, channelSid}: MessageBubbleProps) => {
  const [isActionPending, setIsActionPending] = useState(false);
  const shortcodes = message.source.body ? getParsedShortcodes(message.source.body) : [];

  const handleShortcodeButtonClick = async (event: React.MouseEvent): Promise<void> => {
    const shortcodeText = event.currentTarget.textContent;

    if (shortcodeText) {
      const actionPayload: ChatActionSendMessageTextPayload = {
        body: shortcodeText,
        channelSid,
        messageAttributes: {shortcode: true},
      };

      setIsActionPending(true);

      try {
        await FlexWebChat.Actions.invokeAction('SendMessage', actionPayload);
      } catch (error) {
        withScope(scope => {
          scope.setLevel(Severity.Error);
          scope.setExtras({channelSid, shortcodeText, originalError: error});
          captureMessage('SendMessage from shortcode failed');
        });
      } finally {
        setIsActionPending(false);
      }
    }
  };

  if (!shortcodes.length) {
    return null;
  }

  return (
    <>
      <StyledInvertedTopRoundedCorners />
      <StyledWrapper>
        <StyledShortcodeButtonGroup>
          {shortcodes.map((shortcode, index) => (
            <StyledShortcodeButton
              key={index}
              type="button"
              disabled={isActionPending}
              onClick={handleShortcodeButtonClick}
            >
              {shortcode.text}
            </StyledShortcodeButton>
          ))}
        </StyledShortcodeButtonGroup>
      </StyledWrapper>
    </>
  );
};

type AutopilotMessageBubbleShortcodesProps = Partial<MessageBubbleProps>;

const AutopilotMessageBubbleShortcodes = ({
  message,
  channelSid,
  ...optionalProps
}: AutopilotMessageBubbleShortcodesProps) => {
  if (!message || !channelSid) {
    return null;
  }

  return (
    <AutopilotMessageBubbleShortcodesContent
      message={message}
      channelSid={channelSid}
      {...optionalProps}
    />
  );
};

export default AutopilotMessageBubbleShortcodes;
