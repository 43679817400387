import {UAParser} from 'ua-parser-js';
import {EZCATER_DOMAIN} from '../constants/environmentLookups';
import getIsIframe from './getIsIframe';

const redirectDesktopBrowser = (
  userAgent = navigator.userAgent,
  queryString = window.location.search
): void => {
  const deviceType = new UAParser(userAgent).getDevice().type;
  if (!getIsIframe(queryString) && deviceType !== 'mobile' && deviceType !== 'tablet') {
    const redirectQueryString =
      queryString === '' ? '?open=true' : `${queryString.replace('open=true', '')}&open=true`;
    window.location.replace(EZCATER_DOMAIN + redirectQueryString);
  }
};

export default redirectDesktopBrowser;
