import React from 'react';
import styled from '@emotion/styled';
import {Actions} from '@twilio/flex-webchat-ui';
import FlexActionName from '../constants/FlexActionName';
import {EZCATER_DOMAIN} from '../constants/environmentLookups';
import getIsIframe from '../utilities/getIsIframe';

const StyledContainer = styled.div`
  background-color: #00b06e;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 16px;
  text-align: center;

  h2 {
    font-size: 20px;
    margin-bottom: 12px;
  }

  p {
    font-size: 14px;
    margin-bottom: 8px;
  }

  strong {
    font-weight: bold;
  }

  button {
    appearance: none;
    outline: none;
    border: none;
    background-color: #fff;
    color: #3e90d6;
    border-radius: 4px;
    padding: 10px 24px;
    font-weight: 600;
    margin-top: 8px;
    font-size: 14px;
  }
`;

interface SystemMessageNotificationProps {
  data: {
    webchatTitle: string | null;
    webchatBody: string | null;
  };
}

const SystemMessageNotification = ({data}: SystemMessageNotificationProps) => {
  const {webchatTitle, webchatBody} = data;

  const handleClick = () => {
    Actions.invokeAction(FlexActionName.ToggleChatVisibility);

    if (!getIsIframe(window.location.search)) {
      window.location.replace(EZCATER_DOMAIN);
    }
  };

  return (
    <StyledContainer>
      <div>
        <h2>{webchatTitle || "We're sorry, but chat is unavailable today."}</h2>
        <p>{webchatBody || 'To get in touch, please call us at 1-800-488-1803'}</p>
        <button type="button" onClick={handleClick}>
          Close Chat
        </button>
      </div>
    </StyledContainer>
  );
};

export default SystemMessageNotification;
