import React, {useEffect, useRef} from 'react';
import type {Manager, ChatChannelState} from '@twilio/flex-webchat-ui';
import type {MessageListChildrenProps} from '@twilio/flex-ui-core/src/components/channel/MessageList';
import styled from '@emotion/styled';
import hasOwnProperty from '../utilities/typeGuards/hasOwnProperty';

const doesChannelHaveTaskSid = (channel?: ChatChannelState.ChannelState['source']): boolean => {
  return hasOwnProperty(channel?.attributes, 'taskSid');
};

const messageListScrollToBottom = (): void => {
  const messageListElement = document.querySelector('.Twilio-MessageList');

  if (messageListElement) {
    messageListElement.scrollTop = 9999999;
  }
};

const StyledAlert = styled('div')`
  width: 100%;
  background-color: #ebf7ff;
  border-radius: 4px;
  border-width: 0;
  color: #1e70bf;
  font-size: 14px;
  margin-top: 10px;
  padding: 0.8571428571428571rem;
  padding-right: 1.4285714285714286rem;
`;

type AgentIsBusyNotificationProps = Partial<MessageListChildrenProps> & {
  manager: Manager;
};

const AgentIsBusyNotification = ({manager, channel}: AgentIsBusyNotificationProps) => {
  const chatClientRef = useRef(manager.chatClient);
  const hasTaskEnteredQueueRef = useRef<boolean>(false);
  const isGuestOnlyMember = !!channel && channel.members.size <= 1;
  const isChannelInactive =
    hasOwnProperty(channel?.source?.attributes, 'status') &&
    channel?.source?.attributes.status === 'INACTIVE';
  const shouldShowNotification = isGuestOnlyMember && !isChannelInactive;
  const isLoading = !channel || channel.isLoadingChannel || channel.isLoadingMembers;

  const handleChannelUpdated = (payload?: {
    channel?: ChatChannelState.ChannelState['source'];
    updateReasons?: ('attributes' | 'dateUpdated' | 'lastMessage' | 'lastConsumedMessageIndex')[];
  }): void => {
    const hasUpdatedAttributes = !!payload?.updateReasons?.includes('attributes');

    if (
      !hasTaskEnteredQueueRef.current &&
      hasUpdatedAttributes &&
      doesChannelHaveTaskSid(payload?.channel)
    ) {
      hasTaskEnteredQueueRef.current = true;
      messageListScrollToBottom();
    }
  };

  useEffect(() => {
    const chatClient = chatClientRef.current;

    if (chatClient) {
      chatClient.addListener('channelUpdated', handleChannelUpdated);

      return () => {
        chatClient.removeListener('channelUpdated', handleChannelUpdated);
      };
    }
  }, []);

  if (isLoading || !shouldShowNotification || !doesChannelHaveTaskSid(channel?.source)) {
    return null;
  }

  return <StyledAlert>Connecting you with an agent...</StyledAlert>;
};

export default AgentIsBusyNotification;
