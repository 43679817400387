import * as FlexWebChat from '@twilio/flex-webchat-ui';
import FlexActionName from '../constants/FlexActionName';
import FlexEventName from '../constants/FlexEventName';
import tracker from '../events/tracker';

export type ActionPayload = {
  formData?: {
    question?: string;
  };
};

const sendInitialMessage = ({
  manager,
  data,
}: {
  manager: FlexWebChat.Manager;
  data?: ActionPayload;
}): void => {
  const question = data?.formData?.question;

  if (!question) return;

  const state: {flex: FlexWebChat.AppState} = manager.store.getState();
  const {channelSid} = state.flex.session;
  const {channelState} = state.flex.chat.channels;

  FlexWebChat.Actions.invokeAction(FlexActionName.SendMessage, {
    channelState,
    channelSid,
    body: question,
  });
};

export const actionCallback = ({
  manager,
  data,
}: {
  manager: FlexWebChat.Manager;
  data?: ActionPayload;
}): void => {
  sendInitialMessage({manager, data});
  tracker.track('engagement started');
};

const afterStartEngagement = ({manager}: {manager: FlexWebChat.Manager}): void => {
  FlexWebChat.Actions.addListener(FlexEventName.AfterStartEngagement, (data: ActionPayload) => {
    actionCallback({manager, data});
  });
};

export default afterStartEngagement;
