import {SHORTCODE_ATTRIBUTES_REGEX, SHORTCODE_REGEX} from './constants';

const ponyfilledMatchAll = (input: string, regexp: RegExp): RegExpExecArray[] => {
  const allMatches: RegExpExecArray[] = [];
  let match: ReturnType<RegExp['exec']>;

  // eslint-disable-next-line no-cond-assign
  while ((match = regexp.exec(input)) !== null) allMatches.push(match);

  return allMatches;
};

type ParsedShortcode = {
  text: string;
};

const getShortcodeAttributes = (shortcodeContent: string): ParsedShortcode | undefined => {
  const attributes: Record<string, string> = {};
  const matches = ponyfilledMatchAll(shortcodeContent, SHORTCODE_ATTRIBUTES_REGEX);

  // Assigning key to value that was parsed from shortcode attributes
  matches.forEach(match => {
    if (match[1]) {
      // Match group double quotes
      attributes[match[1].toLowerCase()] = match[2]; // eslint-disable-line prefer-destructuring
    } else if (match[3]) {
      // Match group for single quotes
      attributes[match[3].toLowerCase()] = match[4]; // eslint-disable-line prefer-destructuring
    } else if (match[5]) {
      // Match group for no quotes
      attributes[match[5].toLowerCase()] = match[6]; // eslint-disable-line prefer-destructuring
    }
  });

  if (attributes.text) {
    return {text: attributes.text};
  }
};

const getParsedShortcodes = (text: string): ParsedShortcode[] => {
  const matches = ponyfilledMatchAll(text, SHORTCODE_REGEX);
  const results: ParsedShortcode[] = [];

  matches.forEach(match => {
    if (match && match[3]) {
      const attributes = getShortcodeAttributes(match[3]);

      if (attributes) {
        results.push(attributes);
      }
    }
  });

  return results;
};

export default getParsedShortcodes;
