import {AppConfig} from '@twilio/flex-webchat-ui';
import getParsedLocationSearch from './getParsedLocationSearch';
import getPreEngagementConfiguration from './getPreEngagementConfiguration';

type CustomContext = NonNullable<AppConfig.Config['context']> & {
  trackingId: string;
  chatOriginUrl: string;
  chatReferrerUrl: string;
};

type CustomConfiguration = Omit<AppConfig.Config, 'context'> & CustomContext;

const getCustomConfiguration = (configuration: AppConfig.Config): CustomConfiguration => {
  const query = getParsedLocationSearch();

  const user = {name: query.userName || '', trackingId: query.userTrackingId || ''};
  const locationContext = {
    originUrl: query.originUrl || '',
    referrerUrl: query.referrerUrl || '',
  };

  return {
    ...configuration,
    ...getPreEngagementConfiguration(user),
    context: {
      ...configuration.context,
      trackingId: user.trackingId,
      chatOriginrUrl: locationContext.originUrl,
      chatReferrerUrl: locationContext.referrerUrl,
    },
  };
};

export default getCustomConfiguration;
