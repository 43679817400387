import * as FlexWebChat from '@twilio/flex-webchat-ui';
import hasOwnProperty from './typeGuards/hasOwnProperty';

type Message = {source: FlexWebChat.ChatChannelState.MessageState['source']};

const hasMessageAttribute = <X extends Message, Y extends PropertyKey>(
  message: X | undefined,
  attribute: Y
): message is X & {source: {attributes: Record<Y, unknown>}} => {
  return (
    !!message &&
    'attributes' in message.source &&
    hasOwnProperty(message.source.attributes, attribute)
  );
};

export default hasMessageAttribute;
