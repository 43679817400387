import * as FlexWebChat from '@twilio/flex-webchat-ui';
import hasMessageAttribute from '../utilities/hasMessageAttribute';

type Message = {source: FlexWebChat.ChatChannelState.MessageState['source']};

const isAutopilotMessage = <T extends Message>(
  message?: T
): message is T & {source: {attributes: {autopilot?: boolean}}} => {
  return (
    hasMessageAttribute(message, 'autopilot') &&
    typeof message.source.attributes.autopilot === 'boolean' &&
    message.source.attributes.autopilot
  );
};

export default isAutopilotMessage;
