enum FlexEventName {
  AfterMinimizeChat = 'afterMinimizeChat',
  AfterRestartEngagement = 'afterRestartEngagement',
  AfterSendTyping = 'afterSendTyping',
  AfterSetInputText = 'afterSetInputText',
  AfterStartEngagement = 'afterStartEngagement',
  AfterToggleChatVisibility = 'afterToggleChatVisibility',
  BeforeMinimizeChat = 'beforeMinimizeChat',
}

export default FlexEventName;
