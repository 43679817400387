import {Manager} from '@twilio/flex-webchat-ui';
import afterStartEngagement from './afterStartEngagement';
import afterToggleChatVisibility from './afterToggleChatVisibility';
import afterRestartEngagement from './afterRestartEngagement';
import afterMinimizeChat from './afterMinimizeChat';
import beforeMinimizeChat from './beforeMinimizeChat';
import ToggleChatVisibility from './ToggleChatVisibility';

const actionsToConfigure = [
  afterMinimizeChat,
  afterRestartEngagement,
  afterStartEngagement,
  afterToggleChatVisibility,
  beforeMinimizeChat,
  ToggleChatVisibility,
];

const configureActions = ({manager}: {manager: Manager}) => {
  actionsToConfigure.forEach(action => action({manager}));
};

export default configureActions;
