import {Manager, Actions, AppState} from '@twilio/flex-webchat-ui';
import FlexActionName from '../constants/FlexActionName';

const getMessageReceivedCallback = ({manager}: {manager: Manager}): (() => void) => {
  let isInitialized = false;
  let messageCount = 0;
  let isActionPending = false;

  return () => {
    const state: {flex: AppState} = manager.store.getState();
    const {channelSid} = state.flex.session;
    const {channels} = state.flex.chat;
    const {isEntryPointExpanded} = state.flex.session;

    // The channel is also loaded asynchronously so we need to
    // check this within the Redux subscription
    if (!Object.keys(channels).length || isActionPending) return;

    const {messages} = channels[channelSid];

    // Message count will be 0 on initialization of the
    // client since this call is asyncrhonous
    if (messages.length === 0) return;

    // We need to maintain this state to be able to set the initial
    // message count once the channel is loaded without toggling
    // the widget's visibility
    if (!isInitialized) {
      messageCount = messages.length;
      isInitialized = true;
      return;
    }

    if (messageCount !== messages.length && !isEntryPointExpanded) {
      isActionPending = true;
      messageCount = messages.length;

      Actions.invokeAction(FlexActionName.ToggleChatVisibility)
        .then(() => {
          isActionPending = false;
        })
        .catch(() => {
          isActionPending = false;
        });
    }
  };
};

export default getMessageReceivedCallback;
