import {AppState, Manager} from '@twilio/flex-webchat-ui';

const postToggleVisibilityMessage = ({manager}: {manager: Manager}): void => {
  const state: {flex: AppState} = manager.store.getState();
  const {isEntryPointExpanded} = state.flex.session;
  let currentExpansionState;

  if (currentExpansionState !== isEntryPointExpanded) {
    currentExpansionState = isEntryPointExpanded;

    window.parent.postMessage(
      {action: 'afterToggleChatVisibility', isEntryPointExpanded: currentExpansionState},
      '*'
    );
  }
};

export default postToggleVisibilityMessage;
