import {ThemeConfigProps} from '@twilio/flex-webchat-ui/src/state/AppConfig';

export const colors = {
  white: '#ffffff',
  lightGreen: '#f3f8eb',
  logoGreen: '#00b06e',
  hoverGreen: '#008f5c',
  lightGray: '#f4f7f8',
  darkGreen: '#609b3b',
  gray: '#3c425c',
  darkGray: '#565a5c',
  blue: '#3e90d6',
};

const brandMessageBubbleColors = (bgColor: string, textColor: string) => ({
  Bubble: {
    background: bgColor,
    color: textColor,
  },
  Avatar: {
    background: bgColor,
    color: textColor,
  },
  Header: {
    color: textColor,
  },
});

const brandedColors: ThemeConfigProps['overrides'] = {
  Chat: {
    MessageListItem: {
      FromOthers: brandMessageBubbleColors(colors.lightGray, colors.darkGray),
      FromMe: brandMessageBubbleColors(colors.logoGreen, colors.white),
    },
    MessageInput: {
      Container: {
        background: colors.lightGray,
      },
      Button: {
        background: colors.blue,
        color: colors.white,
      },
    },
    MessageCanvasTray: {
      Container: {
        background: colors.logoGreen,
        color: colors.white,
      },
      Button: {
        background: colors.white,
        color: colors.blue,
      },
    },
  },

  MainHeader: {
    Container: {
      background: colors.logoGreen,
      color: colors.white,
    },
    Logo: {
      fill: colors.white,
    },
  },

  EntryPoint: {
    Container: {
      background: colors.logoGreen,
      color: colors.white,
      '&:hover': {
        backgroundColor: colors.hoverGreen,
      },
    },
  },

  PreEngagementCanvas: {
    Container: {
      background: colors.white,
    },

    Form: {
      SubmitButton: {
        background: colors.blue,
        color: colors.white,
      },
    },
  },
};

export default brandedColors;
