import Environment from '../constants/Environment';

const getEnvironment = (): Environment => {
  const isProduction = window.location.hostname.match(/\.ezcater\.com$/) !== null;

  if (isProduction) {
    return Environment.Production;
  }

  const isSandbox = window.location.hostname.match(/\.sandbox-ezcater\.com$/) !== null;
  if (isSandbox) {
    return Environment.Sandbox;
  }

  const isStaging = window.location.hostname.match(/\.staging-ezcater\.com$/) !== null;
  if (isStaging) {
    return Environment.Staging;
  }

  return Environment.Development;
};

export default getEnvironment;
