import React, {useState} from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import {connect} from 'react-redux';
import {AppState} from '@twilio/flex-webchat-ui';
import {FUNCTIONS_WEBCHAT_URL} from '../constants/environmentLookups';
import tracker from '../events/tracker';
import useIsMounted from '../hooks/useIsMounted';

const StyledButton = styled.button`
  margin-right: 10px;
  border: none;
  background-color: inherit;
  color: white;
  font-size: 14px;
  font-weight: 800;

  :disabled {
    opacity: 0.8;
  }
`;

type EndChatButtonProps = {
  channelSid: string | undefined;
};

const EndChatButton = ({channelSid}: EndChatButtonProps) => {
  const isMounted = useIsMounted();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = async () => {
    setIsDisabled(true);

    try {
      await axios.post(`${FUNCTIONS_WEBCHAT_URL}/end-chat-channel`, {channelSid});
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      if (isMounted()) {
        setIsDisabled(false);
      }
    } finally {
      tracker.track('end chat button clicked');
    }
  };

  if (channelSid) {
    return (
      <StyledButton disabled={isDisabled} onClick={handleClick}>
        End Chat
      </StyledButton>
    );
  }

  return null;
};

const mapStateToProps = (state: {flex: AppState}) => {
  return {
    channelSid: state.flex.chat?.messageList?.activeChatChannel,
  };
};

const connector = connect(mapStateToProps);

export default connector(EndChatButton);
