import React from 'react';
import styled from '@emotion/styled';
import type {MessageBubbleProps} from '@twilio/flex-ui-core/src/components/channel/MessageBubble';
import {SHORTCODE_REGEX} from '../autopilot/constants';

// This is copying the styling of Twilio's native MessageBubble body element.
const StyledBodyWrapper = styled('div')`
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
  margin-top: 3px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.54;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

type AutopilotMessageBubbleBodyProps = Pick<MessageBubbleProps, 'message'> &
  Partial<MessageBubbleProps>;

const AutopilotMessageBubbleBody = ({message}: AutopilotMessageBubbleBodyProps) => {
  const bodyWithoutShortcodes = message.source.body?.replace(SHORTCODE_REGEX, '')?.trim();

  if (!bodyWithoutShortcodes) {
    return null;
  }

  return (
    <StyledBodyWrapper className="Twilio-MessageBubble-Body">
      {bodyWithoutShortcodes}
    </StyledBodyWrapper>
  );
};

type AutopilotMessageBubbleProps = Partial<MessageBubbleProps>;

const AutopilotMessageBubble = ({message, ...optionalProps}: AutopilotMessageBubbleProps) => {
  if (!message) {
    return null;
  }

  return <AutopilotMessageBubbleBody message={message} {...optionalProps} />;
};

export default AutopilotMessageBubble;
