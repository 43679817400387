import {Manager} from '@twilio/flex-webchat-ui';
import postToggleVisibilityMessage from './postToggleVisibilityMessage';
import getMessageReceivedCallback from './getMessageReceivedCallback';

const configureRedux = ({manager}: {manager: Manager}) => {
  const messageReceivedCallback = getMessageReceivedCallback({manager});

  manager.store.subscribe(() => {
    postToggleVisibilityMessage({manager});
    messageReceivedCallback();
  });
};

export default configureRedux;
