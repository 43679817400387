import * as FlexWebChat from '@twilio/flex-webchat-ui';
import FlexEventName from '../constants/FlexEventName';
import tracker from '../events/tracker';

const actionCallback = (): void => {
  tracker.track('chat visibility toggled');
};

const afterMinimizeChat = (): void => {
  FlexWebChat.Actions.addListener(FlexEventName.AfterMinimizeChat, actionCallback);
};

export default afterMinimizeChat;
