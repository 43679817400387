import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'regenerator-runtime/runtime';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {Manager, AppConfig} from '@twilio/flex-webchat-ui';
import {init as sentryInit, captureException} from '@sentry/browser';
import appConfig from './appConfig';
import App from './App';
import {COMMIT_HASH, ENVIRONMENT, SENTRY_DSN} from './constants/environmentLookups';
import redirectDesktopBrowser from './utilities/redirectDesktopBrowser';
import getCustomConfiguration from './utilities/getCustomConfiguration';
import ApolloProvider from './components/Apollo/ApolloProvider';

const WEBCHAT_DIV_ID = 'webchat-root';

sentryInit({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  release: COMMIT_HASH,
});

const createAppRootDiv = (): void => {
  // Guarantee that the node we attached our webchat client to always exists
  const webChatDiv = document.createElement('div');
  webChatDiv.id = WEBCHAT_DIV_ID;
  document.body.appendChild(webChatDiv);
};

type Props = {
  manager: Manager | null;
  error: Error | null;
  configuration: AppConfig.Config;
};

const renderApp = (params: Props): void => {
  ReactDOM.render(
    <ApolloProvider>
      <App {...params} />
    </ApolloProvider>,
    document.getElementById(WEBCHAT_DIV_ID)
  );
};

redirectDesktopBrowser();
createAppRootDiv();

document.addEventListener('DOMContentLoaded', () => {
  const configuration = getCustomConfiguration(appConfig);

  Manager.create(configuration)
    .then(manager => {
      manager.strings.InputPlaceHolder = 'Type here and hit enter';
      manager.strings.MessageCanvasTrayButton = 'Start New Chat';

      return renderApp({configuration, manager, error: null});
    })
    .catch(error => {
      captureException(error);
    });
});
