import * as FlexWebChat from '@twilio/flex-webchat-ui';
import FlexEventName from '../constants/FlexEventName';
import {EZCATER_DOMAIN} from '../constants/environmentLookups';
import getIsIframe from '../utilities/getIsIframe';

const actionCallback = (): void => {
  if (!getIsIframe(window.location.search)) window.location.replace(EZCATER_DOMAIN);
};

const beforeMinimizeChat = (): void => {
  FlexWebChat.Actions.addListener(FlexEventName.BeforeMinimizeChat, actionCallback);
};

export default beforeMinimizeChat;
