import * as FlexWebChat from '@twilio/flex-webchat-ui';
import FlexEventName from '../constants/FlexEventName';
import tracker from '../events/tracker';

const actionCallback = (): void => {
  tracker.track('engagement restarted');
};

const afterRestartEngagement = (): void => {
  FlexWebChat.Actions.addListener(FlexEventName.AfterRestartEngagement, actionCallback);
};

export default afterRestartEngagement;
