import {AppConfig} from '@twilio/flex-webchat-ui';
import {ACCOUNT_SID, FLEX_FLOW_SID} from './constants/environmentLookups';
import brandedColors from './brandedColors';

const appConfig: AppConfig.Config = {
  accountSid: ACCOUNT_SID,
  flexFlowSid: FLEX_FLOW_SID,
  startEngagementOnInit: false,
  colorTheme: {
    overrides: brandedColors,
  },
  markdownSupport: {
    enabled: false,
  },
  componentProps: {
    EntryPoint: {
      tagline: 'Chat with us',
    },
    MessagingCanvas: {
      welcomeMessageText: () => "Welcome to ezCater's support chat",
    },
    MainHeader: {
      imageUrl: '/img/ez-logo.svg',
      showTitle: false,
    },
  },
  fileAttachment: {
    enabled: true,
    readOnly: true,
  },
};

export default appConfig;
