import {useEffect} from 'react';
import {Actions, AppState, Manager} from '@twilio/flex-webchat-ui';
import setResponsiveStyles from '../utilities/setResponsiveStyles';
import tracker from '../events/tracker';
import FlexActionName from '../constants/FlexActionName';
import getParsedLocationSearch from '../utilities/getParsedLocationSearch';

const useQueryParamInitializers = (manager: Manager | null): void => {
  const query = getParsedLocationSearch();

  useEffect(() => {
    tracker.track('chat client loaded');
  }, []);

  useEffect(() => {
    if (!query.iframe) setResponsiveStyles(query);
  }, [query]);

  useEffect(() => {
    if (query.open && manager) {
      const state: {flex: AppState} = manager.store.getState();
      const {isEntryPointExpanded} = state.flex.session;

      if (isEntryPointExpanded) return;

      Actions.invokeAction(FlexActionName.ToggleChatVisibility);
    }
  }, [query, manager]);
};

export default useQueryParamInitializers;
