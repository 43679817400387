import React from 'react';
import styled from '@emotion/styled';
import {EZCATER_ORDER_STATUS_URL} from '../constants/environmentLookups';
import tracker from '../events/tracker';

const StyledOrderStatusTitle = styled('div')`
  font-size: 14px;
  font-style: italic;
`;

const StyledOrderStatusLink = styled('button')`
  font-size: 14px;
  font-style: italic;
  margin-bottom: 30px;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  color: #069;
  text-align: start;
`;

const handleButtonClick = () => {
  window.open(EZCATER_ORDER_STATUS_URL, '_blank');
  tracker.track('order status link clicked');
};

const OrderStatus = () => {
  return (
    <>
      <StyledOrderStatusTitle>Want to check the status of an order?</StyledOrderStatusTitle>
      <StyledOrderStatusLink onClick={() => handleButtonClick()}>Click here!</StyledOrderStatusLink>
    </>
  );
};

export default OrderStatus;
