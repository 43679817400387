import * as FlexWebChat from '@twilio/flex-webchat-ui';
import FlexActionName from '../constants/FlexActionName';

const handleMessage = (event: MessageEvent): void => {
  const doesDataExist = !!event?.data?.action;
  let isActionPending = false;

  if (isActionPending) return;

  if (event.origin && doesDataExist) {
    const isValidAction =
      !!event.data.action && event.data.action === FlexActionName.ToggleChatVisibility;

    if (isValidAction) {
      isActionPending = true;

      FlexWebChat.Actions.invokeAction(FlexActionName.ToggleChatVisibility)
        .then(() => {
          isActionPending = false;
        })
        .catch(() => {
          isActionPending = false;
        });
    }
  }
};

const ToggleChatVisibility = (): void => {
  if (window.addEventListener) window.addEventListener('message', handleMessage, false);
};

export default ToggleChatVisibility;
