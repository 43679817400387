import React from 'react';
import {ApolloProvider as Provider} from '@apollo/client';
import apolloClient from './apolloClient';

interface Props {
  children: React.ReactNode;
}

const ApolloProvider = ({children}: Props) => (
  <Provider client={apolloClient}>
    <>{children}</>
  </Provider>
);

export default ApolloProvider;
