import Environment from './Environment';
import getEnvironment from '../utilities/getEnvironment';

type EnvironmentLookup = {[key in Environment]: string};

export const COMMIT_HASH = process.env.REACT_APP_COMMIT_HASH;
export const ENVIRONMENT = getEnvironment();

const ACCOUNT_SID_LOOKUP: EnvironmentLookup = {
  [Environment.Development]: 'ACe303a1ee0fc1418200dc5ac698c9f33b',
  [Environment.Sandbox]: 'ACe303a1ee0fc1418200dc5ac698c9f33b',
  [Environment.Staging]: 'ACe303a1ee0fc1418200dc5ac698c9f33b',
  [Environment.Production]: 'ACff74c36b9f5006073973c563b906f8aa',
};
export const ACCOUNT_SID: string = ACCOUNT_SID_LOOKUP[ENVIRONMENT];

const FLEX_FLOW_SID_LOOKUP: EnvironmentLookup = {
  [Environment.Development]: 'FO9b3adf80466aaf53cfcde646507bc89e',
  [Environment.Sandbox]: 'FO9b3adf80466aaf53cfcde646507bc89e',
  [Environment.Staging]: 'FO9b3adf80466aaf53cfcde646507bc89e',
  [Environment.Production]: 'FOea537e162dff755bf22f9fb19aa30a13',
};
export const FLEX_FLOW_SID = FLEX_FLOW_SID_LOOKUP[ENVIRONMENT];

const SENTRY_DSN_LOOKUP = {
  development: '',
  production: 'https://44302cec2d8742c88c82c75e4734e852@sentry.io/1411362',
  sandbox: 'https://44302cec2d8742c88c82c75e4734e852@sentry.io/1411362',
  staging: 'https://44302cec2d8742c88c82c75e4734e852@sentry.io/1411362',
};
export const SENTRY_DSN = SENTRY_DSN_LOOKUP[ENVIRONMENT];

const EZCATER_DOMAIN_LOOKUP = {
  development: 'http://localhost:3000',
  production: 'https://ezcater.com',
  sandbox: 'https://dev1.sandbox-ezcater.com',
  staging: 'https://staging-ezcater.com',
};
export const EZCATER_DOMAIN = EZCATER_DOMAIN_LOOKUP[ENVIRONMENT];

const EZCATER_GRAPHQL_URI_LOOKUP = {
  development: 'http://localhost:21216/graphql/marketplace',
  production: 'https://federation-gateway.ezcater.com/graphql/marketplace',
  sandbox: 'https://federation-gateway.dev1.sandbox-ezcater.com/graphql/marketplace',
  staging: 'https://federation-gateway.staging-ezcater.com/graphql/marketplace',
};
export const EZCATER_GRAPHQL_URI = EZCATER_GRAPHQL_URI_LOOKUP[ENVIRONMENT];

const EZCATER_ORDER_STATUS_URL_LOOKUP = {
  development: 'http://localhost:3000/my-account/orders/', // Note that you need the store app running for this to work
  production: 'https://www.ezcater.com/my-account/orders/',
  sandbox: 'https://www.dev1.sandbox-ezcater.com/my-account/orders/',
  staging: 'https://www.staging-ezcater.com/my-account/orders',
};
export const EZCATER_ORDER_STATUS_URL = EZCATER_ORDER_STATUS_URL_LOOKUP[ENVIRONMENT];

const FUNCTIONS_WEBCHAT_URL_LOOKUP = {
  development: 'https://functions-webchat-5605-dev.twil.io',
  staging: 'https://functions-webchat-5605-dev.twil.io',
  sandbox: 'https://functions-webchat-5605-dev.twil.io',
  production: 'https://functions-webchat-4554-dev.twil.io',
};
export const FUNCTIONS_WEBCHAT_URL = FUNCTIONS_WEBCHAT_URL_LOOKUP[ENVIRONMENT];

const RUDDERSTACK_WRITE_KEY_LOOK_UP: EnvironmentLookup = {
  [Environment.Development]: '',
  [Environment.Sandbox]: '2kkHxTcI6GgoE9xXhv53I8tCNJt',
  [Environment.Staging]: '2kkHxTcI6GgoE9xXhv53I8tCNJt',
  [Environment.Production]: '2kkGVR7BW2oEHc3DYzO9f0MgV49',
};
export const RUDDERSTACK_WRITE_KEY: string = RUDDERSTACK_WRITE_KEY_LOOK_UP[ENVIRONMENT];

const RUDDERSTACK_DATA_PLANE_URL_LOOK_UP: EnvironmentLookup = {
  [Environment.Development]: '',
  [Environment.Sandbox]: 'https://stagingtiybptf.dataplane.rudderstack.com',
  [Environment.Staging]: 'https://stagingtiybptf.dataplane.rudderstack.com',
  [Environment.Production]: 'https://ezcaterbimmghi.dataplane.rudderstack.com',
};
export const RUDDERSTACK_DATA_PLANE_URL: string = RUDDERSTACK_DATA_PLANE_URL_LOOK_UP[ENVIRONMENT];
