import {useCallback, useEffect, useRef} from 'react';

const useIsMounted = (): (() => boolean) => {
  const componentIsMounted = useRef(false);

  const handleMount = useCallback(() => {
    return !!componentIsMounted.current;
  }, [componentIsMounted]);

  useEffect(() => {
    componentIsMounted.current = true;

    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  return handleMount;
};

export default useIsMounted;
