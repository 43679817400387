import {injectGlobal} from 'react-emotion';
import type {Query} from './getParsedLocationSearch';

const setResponsiveStyles = ({iframe}: Query): void => {
  if (iframe) return;

  injectGlobal(`
    @media only screen and (max-width: 780px) {
      .Twilio.Twilio-MainContainer {
        height: 100%;
        width: 100%;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
      }
    }
  `);
};

export default setResponsiveStyles;
