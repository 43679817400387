import React, {useEffect} from 'react';
import {
  ContextProvider,
  MainHeader,
  Manager,
  MessageBubble,
  MessagingCanvas,
  MessageList,
  MessageInput,
  RootContainer,
  PreEngagementCanvas,
} from '@twilio/flex-webchat-ui';
import type {MessageBubbleProps} from '@twilio/flex-ui-core/src/components/channel/MessageBubble';
import configureActions from './actions/configureActions';
import configureRedux from './redux/configureRedux';
import AgentIsBusyNotification from './components/AgentIsBusyNotification';
import useQueryParamInitializers from './hooks/useQueryParamInitializers';
import useClientLoadedMessage from './hooks/useClientLoadedMessage';
import useSystemMessage from './hooks/useSystemMessage';
import OrderStatus from './components/OrderStatus';
import EndChatButton from './components/EndChatButton';
import AutopilotMessageBubble from './components/AutopilotMessageBubble';
import AutopilotMessageBubbleShortcodes from './components/AutopilotMessageBubbleShortcodes';
import isAutopilotMessage from './autopilot/isAutopilotMessage';

type AppProps = {
  manager: Manager | null;
};

const App = ({manager}: AppProps) => {
  useQueryParamInitializers(manager);
  useClientLoadedMessage(manager);
  useSystemMessage();

  useEffect(() => {
    if (manager) {
      configureActions({manager});
      configureRedux({manager});

      MessageList.Content.add(
        <AgentIsBusyNotification manager={manager} key="agent-is-busy-notification" />,
        {align: 'end'}
      );
      MessageBubble.Content.add(<AutopilotMessageBubble key="autopilot-message-bubble" />, {
        if: ({message}: MessageBubbleProps) => isAutopilotMessage(message),
      });
      MessageBubble.Content.add(
        <AutopilotMessageBubbleShortcodes key="autopilot-message-bubble-shortcodes" />,
        {
          sortOrder: 999,
          if: ({message}: MessageBubbleProps) => isAutopilotMessage(message),
        }
      );
      MessageBubble.Content.remove('body', {
        if: ({message}: MessageBubbleProps) => isAutopilotMessage(message),
      });

      PreEngagementCanvas.Content.add(<OrderStatus key="order-status" />);

      MainHeader.Content.add(<EndChatButton key="end-chat" />, {
        sortOrder: -1,
        align: 'end',
      });
      MainHeader.Content.remove('close-button');
    }
  }, [manager]);

  if (!manager) {
    return null;
  }

  return (
    <ContextProvider manager={manager}>
      <RootContainer />
    </ContextProvider>
  );
};

MessagingCanvas.defaultProps.predefinedMessage = undefined;
MessageInput.defaultProps.useSeparateInputStore = true;

export default App;
