import * as FlexWebChat from '@twilio/flex-webchat-ui';

type User = {
  name?: string | null;
  trackingId?: string | null;
};

function getPreEngagementConfiguration(user?: User): Partial<FlexWebChat.AppConfig.Config> {
  return {
    preEngagementConfig: {
      description:
        "Welcome to ezCater's support chat. Fill in the information below to get started.",
      fields: [
        {
          attributes: {
            name: 'friendlyName',
            placeholder: 'Full Name',
            required: true,
            value: user?.name || undefined,
            type: 'text',
          },
          label: 'What is your full name?',
          type: 'InputItem',
        },
        {
          attributes: {
            name: 'question',
            placeholder: 'Your Question',
            required: true,
            rows: 6,
            type: 'text',
          },
          label: 'How can we help you today?',
          type: 'TextareaItem',
        },
      ],
      submitLabel: 'Start Chat',
    },
  };
}

export default getPreEngagementConfiguration;
