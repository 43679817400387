import qs from 'qs';

export type Query = {
  iframe?: string;
  userName?: string;
  userTrackingId?: string;
  originUrl?: string;
  referrerUrl?: string;
  open?: string;
};

const getParsedLocationSearch = (): Query => {
  return qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
};

export default getParsedLocationSearch;
