import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from '@apollo/client';
import * as Sentry from '@sentry/browser';
import {onError} from '@apollo/client/link/error';
import {EZCATER_GRAPHQL_URI} from '../../constants/environmentLookups';

const cache = new InMemoryCache();

const captureException = (error: Error, extras: any) => {
  Sentry.withScope(scope => {
    scope.setExtra('errorInfo', extras);
    Sentry.captureException(error);
  });
};

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(graphQLError => {
      captureException(graphQLError, 'GraphQL - Error:');
    });
  }

  if (networkError) captureException(networkError, 'GraphQL - Network Error:');
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    new HttpLink({
      uri: EZCATER_GRAPHQL_URI,
      headers: {
        'X-EZCATER-SERVICE-NAME': 'liberty-webchat',
        'X-EZCATER-SOURCE': 'client',
      },
    }),
  ]),
  cache,
});

export default apolloClient;
