import {useEffect} from 'react';
import {AppState, Manager} from '@twilio/flex-webchat-ui';

const useClientLoadedMessage = (manager: Manager | null): void => {
  useEffect(() => {
    if (!manager) return;

    const state: {flex: AppState} = manager.store.getState();
    const {isEntryPointExpanded} = state.flex.session;

    window.parent.postMessage({action: 'webchatClientLoaded', isEntryPointExpanded}, '*');
  }, [manager]);
};

export default useClientLoadedMessage;
