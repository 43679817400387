import React, {useEffect} from 'react';
import {PreEngagementCanvas} from '@twilio/flex-webchat-ui';
import {gql, useQuery} from '@apollo/client';
import SystemMessageNotification from '../components/SystemMessageNotification';

export const WEBCHAT_SYSTEM_MESSAGE_QUERY = gql`
  query WebchatSystemMessage {
    webchatSystemMessage {
      webchatBody
      webchatTitle
    }
  }
`;

type WebchatSystemMessage = {
  webchatSystemMessage: {
    __typename: 'SystemMessage';
    webchatBody: string | null;
    webchatTitle: string | null;
  } | null;
};

const useSystemMessage = () => {
  const {data, loading} = useQuery<WebchatSystemMessage>(WEBCHAT_SYSTEM_MESSAGE_QUERY);

  useEffect(() => {
    if (!loading && data?.webchatSystemMessage) {
      PreEngagementCanvas.Content.replace(
        <SystemMessageNotification
          data={data.webchatSystemMessage}
          key="queue-removal-notification"
        />
      );
    }
  }, [loading, data]);
};

export default useSystemMessage;
